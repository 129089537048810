<template>
  <div class="flex justify-center my-auto">
    <div class="relative max-w-full">
      <AutoComplete 
        v-model="searchTerm"
        :suggestions="suggestions" 
        placeholder="Søk i prosjekter og tråder" 
        class="[&>input]:w-[36rem] max-w-full" 
        @complete="search"
        @item-select="itemSelect"
        scrollHeight="600px"
        inputId="refIndex"
        :completeOnFocus="searchTerm && searchTerm.length ? true : false"
        optionLabel="item.name"
      >   
        <template #option="slotProps">
          <div class="flex flex-col max-w-[36rem]">
            <div class="flex justify-between text-gray-500 text-xs">
              <span class="truncate">{{ slotProps.option.item.users }}</span>
              <span>{{ moment(slotProps.option.item.latestActivityDate).calendar() }}</span>
            </div>
            <div class="flex items-center gap-2 mt-1">
              <span class="truncate">{{ slotProps.option.item.name }}</span>
            </div>
            
            <div class="flex items-center gap-2 mt-1">
              <span class=" text-gray-500 text-sm truncate">{{ content(slotProps.option.item) }}</span>
            </div>
          </div>
        </template>
      </AutoComplete>
      <i 
        v-show="searchTerm && searchTerm.length" 
        class="pi pi-times absolute right-3 top-[calc(50%-9px)] text-gray-500 cursor-pointer" 
        style="font-size: 18px;" 
        @click="searchTerm = null"
      ></i>
    </div>
  </div>
</template>


<script setup>
import Fuse from 'fuse.js'
import moment from 'moment'

const projects = useProjectStore()
const threads = useThreadsStore()
const auth = useAuthStore()

const router = useRouter()

const searchTerm = ref(null)
const suggestions = ref([])


const fuseOptions = {
	// isCaseSensitive: false,
	// includeScore: false,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	// location: 0,
	// threshold: 0.6,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	// fieldNormWeight: 1,
	keys: [
		"name",
    "users"
	]
}

const formatUsers = (users) => {
  if(typeof users === 'undefined' || !users || !Array.isArray(users))
    return null
  const str = users.filter(el => auth.user && el.id !== auth.user.id).map(el => el = el.firstName).sort()
  
  return str.length ? str.join(', ') : 'Meg'
}

const projectsList = computed(() => projects.data.map(el => {
  return{
    ... el,
    type:'project',
    name: el.projectName,
    users: formatUsers(el.users.map(e => e = e.user))
  }
}))

const threadsList = computed(() => threads.data.map(el => {
  return{
    ... el,
    type:'thread',
    name: el.threadTitle,
    users: formatUsers(el.users)
  }
}))

watch((projectsList.value.length, threadsList.value.length), () => {
  if(projectsList.value.length && threadsList.value.length)
    fuse.value = new Fuse([...projectsList.value, ...threadsList.value], fuseOptions)
})


const fuse = ref(new Fuse([...projectsList.value, ...threadsList.value], fuseOptions))

const search = (event) => {
  if(event.query && event.query.length)
    suggestions.value = fuse.value.search(event.query)
  else 
    suggestions.value = []
}



const content = (suggestion) => {
  if(suggestion.type == 'thread' && suggestion.messages.length)
    return suggestion.messages[0].message
  
  return null
}

const itemSelect = ({ value }) => {
  console.log(value)
  if(value.item.type == 'project'){
    router.push({path: '/', query: { project: value.item.id , scroll: true}})
  }
    
  
  else if(value.item.type == 'thread'){
    router.push({path: '/', query: { project: value.item.projectId, thread: value.item.id, scroll: true}})
  }
} 

</script>